<template>
	<div class="pro_detail">
		<div class="proTop">
			<div class="widthBox">
				<el-row :gutter="60">
					<el-col class="hidden-sm-and-up hidden-xs-only" :xs="24">
						<h4 class="m_pro_title">{{pageData.product}}</h4>
					</el-col>
					<el-col :span="11" :xs="24">
						<div class="left">
							<img v-if="photoIndex == index" v-for="(item,index) in pageData.product_photo" :src="item.photo" class="pic">
							<div class="imgBpx acea-row row-center-wrapper">
								<div @click="picChange" data-type="1" class="imgBtn el-icon-arrow-left">
								</div>
								<div class="imgS hidden-xs-only acea-row row-center-wrapper">
									<img @click="picChange" :data-num="index" :class="photoIndex == index?'active':''" v-for="(item,index) in pageData.product_photo" :src="item.photo">
								</div>
								<div @click="picChange" data-type="2" class="imgBtn el-icon-arrow-right"></div>
							</div>
						</div>
					</el-col>
					<el-col :span="13" :xs="24">
						<div class="pro_title">
							<!-- <h4>男士私密养护系列</h4> -->
							<div class="en">{{pageData.enproduct}}</div>
							<h2>{{pageData.product}}</h2>
							<div class="info">
								<p v-for="item in pageData.remarks">{{item}}</p>
							</div>
						</div>
						<div class="item" v-for="item in pageData.product_explain">
							<div class="label">{{item.key}}</div>
							<div class="txt">
								{{item.value}}
							</div>
						</div>
						<div class="pro_footer">
							<a href="https://phromonjpn.com/">
								<div class="left">{{pageData.price}} <span>円(税抜)</span> </div>
								<div class="right">即購入です</div>
							</a>
						</div>
						
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="collocation" v-if="pageData.collocation_product.length>0">
			<titleOne en='collocation' title="搭配使用"></titleOne>
			<div class="widthBox">
				<el-row type="flex" :gutter="13" class="list" justify="center">
					<el-col :span="8" v-for="item in pageData.collocation_product">
						<div class="item">
							<img :src="item.main_figure" alt="" class="pic">
							<div class="en">{{item.enproduct}}</div>
							<div class="name">{{item.product}}</div>
							<div class="more" @click="myUrl(item.id)">クリックしてもっと見る</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="page_detail">
			<titleOne :en='pageData.enproduct' :title="pageData.product"></titleOne>
			<div class="detail">
				<div class="widthBox" v-html="pageData.content"></div>
			</div>
		</div>
	</div>

</template>

<script>
	import titleOne from '@/components/titleOne.vue'
	export default {
		name: 'proDetail',
		components: {
			titleOne
		},
		data: function() {
			return {
				pageData:{},
				photoIndex:0,
				id:''
			};
		},
		created() {
			this.id = this.$route.query.id
			this.getData()
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods:{
			getData(){
				this.$api.get('home/index/productDetail',{
					id:this.id
				}).then(res=>{
					this.pageData = res.data
				})
			},
			myUrl(e){
				this.pageData={}
				this.photoIndex=0
				this.id = e
				this.getData()
			},
			picChange(e){
				var type = e.currentTarget.dataset.type
				var photoIndex = this.photoIndex
				var num = e.currentTarget.dataset.num
				if(type == 1){
					if(photoIndex == 0){
						photoIndex = this.pageData.product_photo.length-1
					}else{
						photoIndex--
					}
				}else if(type == 2){
					if(photoIndex == (this.pageData.product_photo.length-1)){
						photoIndex=0
					}else{
						photoIndex++
					}
				}else{
					photoIndex = num
				}
				this.photoIndex = photoIndex
			}
		}
	}
</script>

<style lang="scss">
	$fontColor:#1D2A55;

	.pro_detail {
		padding-top: 80px;
		width: 100%;

		.proTop {
			.left {
				width: 100%;

				.pic {
					width: 100%;
				}

				.imgBpx {
					margin-top: 40px;

					.imgBtn {
						width: 80px;
						height: 120px;
						text-align: center;
						line-height: 120px;
						font-size: 28px;
						font-weight: bold;
						cursor: pointer;
					}

					.imgS {
						img {
							width: 120px;
							height: 120px;
							margin: 0 10px;
							cursor: pointer;
							opacity: 0.6;
							&.active{
								opacity: 1;
							}
						}
					}
				}
			}

			.pro_title {
				padding: 40px 0 30px 0;
				border-bottom: 1px solid $fontColor;

				h4 {
					font-size: 20px;
					color: $fontColor;
					line-height: 40px;
				}

				h2 {
					font-size: 40px;
					color: $fontColor;
					line-height: 56px;
				}

				.en {
					font-size: 18px;
					line-height: 36px;
					color: $fontColor;
				}

				.info {
					font-size: 20px;
					margin-top: 20px;
					line-height: 30px;
					p{
						font-size:20px;
					}
					color: rgba($color:$fontColor, $alpha: 0.7);
				}
			}
			.pro_footer{
				width: 100%;
				box-sizing: border-box;
				margin-top: 30px;
				div{
					box-sizing: border-box;
					width: 50%;
					text-align: center;
					font-size: 36px;
					height: 100px;
					line-height: 100px;
				}
				a{
					display: flex;
					justify-content: space-around;
					align-items: center;
					width: 100%;
					.left{
						border: 1px solid #1d2a55;
						color: #1d2a55;
						font-size: 42px;
						span{
							font-size: 24px;
						}
					}
					.right{
						background: #1d2a55;
						border: 1px solid #1d2a55;
						font-size: 36px;
						color: #fff;
					}
				}
			}
			.item {
				border-bottom: 1px solid $fontColor;
				padding: 20px 0;

				.label {
					font-size: 24px;
					line-height: 36px;
					color: $fontColor;
					font-weight: bold;
				}

				.txt {
					font-size: 20px;
					line-height: 32px;
					margin-top: 6px;
					color: rgba($color:$fontColor, $alpha: 0.7);
				}
			}
		}

		.collocation {
			border-top: 1px solid $fontColor;
			margin-top: 80px;
			padding-top: 80px;
			
			.list{
				margin-top:50px;
			}
			.item {
				width: 100%;
				text-align: center;

				.pic {
					width: 100%;
				}
				.en{
					font-size:18px;
					color:rgba($color:$fontColor, $alpha: 0.5);
					margin-top:20px;
					line-height:28px;
				}
				.name{
					font-size:24px;
					line-height:28px;
					font-weight: bold;
					margin-top:5px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.more{
					width:220px;
					line-height:40px;
					color:#Fff;
					margin:40px auto 0;
					font-size:18px;
					text-align: center;
					background:$fontColor;
					border-radius:8px;
					cursor: pointer;
				}
			}
		}
		
		.page_detail{
			border-top: 1px solid $fontColor;
			margin-top: 80px;
			padding-top: 80px;	
			.widthBox{
				padding-top:40px;
			}
			video{
				width:100%;
				height:500px;
			}
		}
	}
	
	@media screen and(max-width:1400px) {
		.pro_detail{
			.proTop{
				.left{
					.imgBpx{
						margin-top:20px;
						.imgBtn{
							width:60px;
						}
						.imgS{
							img{
								width:120px;
								height:120px;
								margin:0 5px;
							}
						}
					}
				}
				.pro_title{
					padding:30px 0 25px;
					h4{
						font-size:18px;
					}
					h2{
						font-size:32px;
					}
					.en{
						font-size:16px;
					}
					.info{
						margin-top:10px;
						p{
							font-size:18px;
						}
					}
				}
				.item{
					padding:10px 0;
					.label{
						font-size:20px;
					}
					.txt{
						font-size:20px;
					}
				}
			}
			.collocation{
				margin-top:50px;
				padding-top:50px;
			}
			.page_detail{
				margin-top:50px;
				padding-top:50px;
				
			}
		}
	}
	@media screen and(max-width:1200px) {
		.pro_detail{
			.proTop{
				.left{
					.imgBpx{
						margin-top:10px;
						.imgBtn{
							width:44px;
						}
						.imgS{
							img{
								width:100px;
								height:100px;
							}
						}
					}
				}
				.pro_title{
					padding:15px 0 10px;
					h4{
						font-size:16px;
					}
					h2{
						font-size:28px;
					}
					.en{
						font-size:14px;
					}
					.info{
						margin-top:5px;
						p{
							font-size:16px;
						}
					}
				}
				.item{
					.label{
						font-size:16px;
					}
					.txt{
						font-size:16px;
					}
				}
			}
			.collocation{
				margin-top:40px;
				padding-top:40px;
			}
			.page_detail{
				margin-top:40px;
				padding-top:40px;
				
			}
		}
	}
	@media screen and(max-width:768px) {
		.widthBox{
					padding:0;
					width: 100%;
					p{
						width: 100%;
						img{
							width: 100% !important;
							height:auto !important;
						}
					}
				}
		.pro_detail{
			padding-top:20px;
			.proTop{
				// border: 1px solid #dedede;
				margin:0 20px;
				padding-bottom:20px;
				.pro_title{
					padding:15px 0 10px;
					.en{
						font-size: 12px;
						color: #3E404D;
					}
					h2{
						font-size:16px;
						line-height: 18px;
						color: #272E4D;
					}
					.en{
						font-size:12px;
					}
					.info{
						margin-top:5px;
						p{
							font-size:14px;
							color: #272E4D;
						}
					}
				}
				.pro_footer{
					div{
						font-size: 16px;
						height: 60px;
						line-height: 60px;
					}
					.left{
						font-size: 16px !important;
						height: 40px;
						line-height: 40px;
						span{
						font-size: 14px !important;
						}
						margin: 0px ;
					}
					.right{
						font-size: 16px !important;
						height: 40px;
						line-height: 40px;
					}
				}
				.item{
					.txt{
							font-size:14px;
							color: #272E4D;
					}
					.label{
						color: #8A8D99;
						font-size: 14px;
					}
				}
				.m_pro_title{
					background:#F8FAFC;
					font-size:16px;
					color:#1D2A55;
					line-height:48px;
					padding:0 10px;
				}
				.left{
					position: relative;
					border-bottom:1px solid #eee;
					margin-bottom:20px;
					.imgBpx{
						.imgBtn{
							position: absolute;
							top:50%;
							width:40px;
							height:40px;
							line-height:40px;
							text-align: center;
							background:#FFF;
							border-radius:50%;
							font-size:18px;
							border: 1px solid #bfcfd8;
							transform: translate(0,-50%);
							&.el-icon-arrow-left{
								left:0px;
							}
							&.el-icon-arrow-right{
								right:0px;
							}
						}
					}
				}
				.item{
					padding:5px 0px;
					
					.label{
						// margin-right:10px;
					}
					.label,.txt{
						// display: inline;
						// line-height:20px;
						// font-weight: 500;
						// color:#333333;
					}
				}
			}
			
			.collocation{
				padding-top:20px;
				margin-top:0;
				border:0;
				.list{
					margin-top:20px;
					.item{
						.en{
							font-size:10px;
							line-height:16px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							margin-top:8px;
						}
						.name{
							font-size:16px;
							line-height:20px;
						}
						.more{display: none;}
					}
				}
			}
			.page_detail{
				margin-top:0px;
				border-top:0;
				video{
					height:240px;
				}
			}
		}
	}
</style>
